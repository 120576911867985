"use client";

import Link from "next/link";
import Header from "./Header";

export default function Error({ error, reset }) {
  return (
    <>
      <Header
        altText={`500`}
        title={`FactoryCat - 500 Error`}
        paragraph={`Could not find requested resource`}
      />
      <div className="standard-page">
        <div>
          <h1>Not Found</h1>
          <p>Could not find requested resource</p>
          <Link href="#" onClick={() => window.location.reload()}>
            Try Again
          </Link>
        </div>
      </div>
    </>
  );
}
